import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
//  import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';

// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);
//   const redirectLoggedInToHome = () => redirectLoggedInTo(['/tabs/tab3']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main-menu/main-menu.module').then( m => m.MainMenuPageModule)
  },
  // {
  //   path: 'tabs',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  // },
  // {
  //   path: 'tab4',
  //   loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  // },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
  },
  {
    path: 'how-to-use-app',
    loadChildren: () => import('./how-to-use-app/how-to-use-app.module').then( m => m.HowToUseAppPageModule)
  },
  {
    path: 'rating',
    loadChildren: () => import('./rating/rating.module').then( m => m.RatingPageModule)
  },
  {
    path: 'topical-discussion',
    loadChildren: () => import('./topical-discussion/topical-discussion.module').then( m => m.TopicalDiscussionPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  // {
  //   path: 'about',
  //   loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  // },
  // {
  //   path: 'videos',
  //   loadChildren: () => import('./videos/videos.module').then( m => m.VideosPageModule)
  // },
  // {
  //   path: 'audio',
  //   loadChildren: () => import('./audio/audio.module').then( m => m.AudioPageModule)
  // },
  // {
  //   path: 'photos',
  //   loadChildren: () => import('./photos/photos.module').then( m => m.PhotosPageModule)
  // },
  // {
  //   path: 'documents',
  //   loadChildren: () => import('./documents/documents.module').then( m => m.DocumentsPageModule)
  // },
  // {
  //   path: 'selectcounselor',
  //   loadChildren: () => import('./selectcounselor/selectcounselor.module').then( m => m.SelectcounselorPageModule)
  // },
  // {
  //   path: 'counselingchat',
  //   loadChildren: () => import('./counselingchat/counselingchat.module').then( m => m.CounselingchatPageModule)
  // },
  // {
  //   path: 'librarianrating',
  //   loadChildren: () => import('./librarianrating/librarianrating.module').then( m => m.LibrarianratingPageModule)
  // },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
